<template>
  <div class="member-notes">
    <m-resource-notes
      :notes="notes"
      :create="create"
      :allow-create="canCreateNote"
    />
  </div>
</template>

<script>
import { notes as memberNotes, addNote } from 'api/members'
export default {
  data () {
    return {
      notes: null
    }
  },
  computed: {
    canCreateNote () {
      return this.$store.getters.hasPermission('users.notes.store')
    }
  },
  mounted () {
    this.getNotes().then(notes => {
      this.notes = notes.data
    })
  },
  methods: {
    getNotes () {
      return memberNotes(this.$route.params.username)
    },
    create (content) {
      return addNote(this.$route.params.username, content).then(note => {
        this.notes.unshift(note.data)
        this.$q.notify({ color: 'positive', icon: 'mdi-check', position: 'bottom', message: 'Note added!' })
      })
    }
  }
}
</script>

<style lang="stylus">
.member-notes
  width 768px
  margin auto
</style>
